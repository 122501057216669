import * as React from 'react';
import md5 from 'md5';
import { PropaneSharp } from '@mui/icons-material';
import {Tooltip, Grid, Button, Typography, Icon, Divider, Paper, Card, CardHeader, Box, Popper} from '@mui/material';
import {useState, useEffect} from 'react';
import tinycolor from 'tinycolor2';
const backendUrl = "https://data.coinchan.xyz";
export default function PeekReply(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [postContent, setPostContent] = useState("");
  const [postSubject, setPostSubject] = useState("");
  const [postTid, setPostTid] = useState("");
  const [postDate, setPostDate] = useState("");
  const [postName, setPostName] = useState("");
  const [postThumb, setPostThumb] = useState("");
  const [isModpost, setIsModpost] = useState(false);
  const [isOp, setIsOp] = useState(false);
  const [exists, setExists] = useState(false);

  var quote = new RegExp('>>');
  var greentext = new RegExp('^>');

  const updatePostContent = () => {
    var pc = window.getPeekContent(props.line.split(">>")[1]);
    setPostContent(pc);
  }

  const updatePostThumb = () => {
    var pt = window.getPeekThumb(props.line.split(">>")[1]);
    setPostThumb(pt);
  }

  function idColor(string) {
    return '#' + md5(string).slice(0, 6);
}
function idTextColor(string) {
    var thisIdColor;
    thisIdColor = tinycolor(string);
    if (thisIdColor.getBrightness(string) > 128){

        return 'black';
    }else{

        return 'white';
    }
}

function getStyle(){
    
    return {float: 'left', width: "auto", maxWidth: "200px", maxHeight:'300px', padding: '20px', paddingTop: '4px'};
    
}
function getMobileStyle(){

    return {float: 'left', width: "auto", maxWidth: "150px", maxHeight:'300px', padding: '20px', paddingTop: '2px'};
    
}






  const grabInfo = (event) => {
    var thisId = props.line.split(">>")[1];
    var pc = window.getPeekContent(thisId);
    setPostContent(pc);
    
    var pt = window.getPeekThumb(thisId);
    setPostThumb(pt);

    var ptid = window.getPeekTid(thisId);
    setPostTid(ptid);

    var pname = window.getPeekName(thisId);
    setPostName(pname);

    var pdate = window.getPeekDate(thisId);
    setPostDate(pdate);

    var mp = window.isModpost(thisId);
    setIsModpost(mp);

    if (ptid){
      setExists(true);
    }


  };




  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    var respondingTo = props.line.split(">>")[1];
    var pop = window.isOp(respondingTo);
    setIsOp(pop);
    window.addBacklink("bl" + respondingTo, "bl" + props.thisReply);

  }, []);



  return (
    <div style={{display: 'inline'}}>
      
      <Box sx={{display: {xs: 'none', sm:'inline'}}}>
      <a href={'#' + props.line.split(">>")[1]} style={{ color:'#3399ff', display: 'inline'}} aria-describedby={id}  onMouseEnter={(event) => (setAnchorEl(event.currentTarget), grabInfo())} onMouseLeave={(event) => setAnchorEl(null)}>
        {props.line}
        {isOp && <span> (OP)</span>}
      </a>
        </Box>

        <Box sx={{display: {xs: 'inline', sm:'none'}}}>
      <a href='#!' style={{ color:'#3399ff'}} aria-describedby={id}  onMouseEnter={(event) => (setAnchorEl(event.currentTarget), grabInfo())} onMouseLeave={(event) => setAnchorEl(null)}>
        {props.line}
        {isOp && <span> (OP)</span>}
      </a>
      <a href={'#' + props.line.split(">>")[1]} style={{ color:'#3399ff'}}> #</a>
      </Box>

        
    {exists && 
    <Popper sx={{display: {xs:'none', sm:'block'}}} placement='right' id={id} open={open} anchorEl={anchorEl}>
      
      <div> 
        <Grid  spacing={0} justifyContent='center' marginLeft={{sm: '24px', lg: '10px', xl: '20px'}} >

            <Grid  item flexWrap='nowrap'  sx={{maxWidth: {xs: '80%', sm:'800px'}}}  >

            <Card sx={{background:"#0d2946", backdropFilter:"blur(6px)"}}>
            <Box sx={{ marginTop:0, background:"#183049"}}>
            <Typography sx={{color: '#B268BB', fontSize: {xs: 12, sm: 14}}} marginLeft={1} marginRight={1} align='left'>
            <span  style={{color: '#B268BB', fontWeight:'bold'}}>{postSubject} </span> 
            <span style={{color: 'white', fontWeight:'bold'}}>  {postName}</span>
            {isModpost && <span style={{color: 'red'}}>##Admin</span> }
            <span style={{color: 'white'}}>   ID:(<Box style={{color: idTextColor(idColor(postTid)), padding: '0 4px', height: '14px', fontSize: 10, backgroundColor: idColor(postTid), display: 'inline-block', borderRadius: '8px'}}><span sx={{fontSize: 4 }}>{postTid}</span></Box>)</span>
            <span style={{color: 'white'}}>   {postDate} No.</span>
            
            <span style={{color: 'white', textDecoration: 'none'}}>{props.line.split(">>")[1]} </span>
            
            </Typography>
            </Box>

                {/* Load two separate images for mobile and desktop since
                this shit doesn't support breakpoints to set the fucking width
                */}
                {postThumb &&
                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                <img style={getMobileStyle()}  src={backendUrl + postThumb}></img>
                </Box>
                }
                {postThumb &&
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                <img  style={getStyle()}  src={backendUrl  + postThumb}></img>
                </Box>
                } 
                  
                <Typography align='left' width='100%' fontSize={{xs: 12, sm: 14, lg: 14}}  lineHeight={1.5} style={{padding: '8px', whiteSpace: 'pre-line' }}>{postContent.split(/\r?\n/).map(line => {
                    
                    return <div key={line + "peek"}>
                        {quote.test(line) && 
                            <div> 
                                
                                <PeekReply line={line} />
                                {'\r\n'}
                            </div>}
                            {quote.test(line) != true && greentext.test(line) &&  <div>
                                <span key={line} style={{color: '#66bb6a'}}>{line}</span>
                            </div>}
                            
                            {quote.test(line) != true && greentext.test(line) != true && <div>
                                <span key={line}>{line}</span>
                                </div>
                            }

                            {
                                line == "" && <span>{'\n'}</span>
                            }
                                

                            
                        </div>;

                })}</Typography>
        

            </Card>
            </Grid>
        </Grid>
        

        </div>
      </Popper>}



      { exists && <Popper sx={{display: {xs:'block', sm:'none'}}} placement='bottom' id={id} open={open} anchorEl={anchorEl}>
      <div> 
        <Grid  spacing={0} justifyContent='center' marginLeft={{xs: '24px', sm: '24px', lg: '10px', xl: '20px'}} >

            <Grid  item flexWrap='nowrap'  sx={{maxWidth: {xs: '80%', sm:'800px'}}}  >

            <Card sx={{background:"rgba(13, 41, 70, 0.95)", backdropFilter:"blur(6px)"}}>
            <Box sx={{ marginTop:0, background:"#183049"}}>
            <Typography sx={{color: '#B268BB', fontSize: {xs: 12, sm: 14}}} marginLeft={1} marginRight={1} align='left'>
            <span  style={{color: '#B268BB', fontWeight:'bold'}}>{postSubject} </span> 
            <span style={{color: 'white', fontWeight:'bold'}}>  {postName}</span>
            {isModpost && <span style={{color: 'red'}}>##Admin</span> }
            <span style={{color: 'white'}}>   ID:(<Box style={{color: idTextColor(idColor(postTid)), padding: '0 4px', height: '14px', fontSize: 10, backgroundColor: idColor(postTid), display: 'inline-block', borderRadius: '8px'}}><span sx={{fontSize: 4 }}>{postTid}</span></Box>)</span>
            <span style={{color: 'white'}}>   {postDate} No.</span>
            
            <span style={{color: 'white', textDecoration: 'none'}}>{props.line.split(">>")[1]} </span>
            
            </Typography>
            </Box>

                {/* Load two separate images for mobile and desktop since
                this shit doesn't support breakpoints to set the fucking width
                */}
                {postThumb &&
                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                <img style={getMobileStyle()}  src={backendUrl + postThumb}></img>
                </Box>
                }
                {postThumb &&
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                <img  style={getStyle()}  src={backendUrl  + postThumb}></img>
                </Box>
                } 
                  
                <Typography align='left' width='100%' fontSize={{xs: 12, sm: 14, lg: 14}}  lineHeight={1.5} style={{padding: '8px', whiteSpace: 'pre-line' }}>{postContent.split(/\r?\n/).map(line => {
                    
                    return <div key={line}>
                        {quote.test(line) && 
                            <div> 
                                
                                <PeekReply line={line} />
                                {'\r\n'}
                            </div>}
                            {quote.test(line) != true && greentext.test(line) &&  <div>
                                <span key={line} style={{color: '#66bb6a'}}>{line}</span>
                            </div>}
                            
                            {quote.test(line) != true && greentext.test(line) != true && <div>
                                <span key={line}>{line}</span>
                                </div>
                            }

                            {
                                line == "" && <span>{'\n'}</span>
                            }
                                

                            
                        </div>;

                })}</Typography>
        

            </Card>
            </Grid>
        </Grid>
        

        </div>
      </Popper>}
    </div>
  );
}
