import logo from '../logo.svg';


import * as React from 'react';
import {memo} from 'react';
import {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Link, useParams} from "react-router-dom";
import Draggable from 'react-draggable';
import md5 from 'md5';
import tinycolor from 'tinycolor2';
import {Grid, Button, Typography, Icon, Divider, Paper, Card, CardHeader, Box, Alert} from '@mui/material';

import PostPreview from "./PostPreview";
import ThreadReply from "./ThreadReply";
import NewReplyForm from "./NewReplyForm";
import ThreadOp from './ThreadOp';
import PeekReply from './PeekReply';
import QuickReply from './QuickReply';
const backendUrl = "https://data.coinchan.xyz";





const ViewThread = (props) => {


    var quoteVar = "";
    function setQuoteVar(value){
        quoteVar = value;
        console.log('I SET IT IN THE PARENT')
    }


    let {board, postId} = useParams();
    let [threadInfo, setThreadInfo] = useState([]);
    var tc = tinycolor();
    const threadBottom = useRef(null)

    const executeScroll = () => threadBottom.current.scrollIntoView()    
    function idColor(string) {
        return '#' + md5(string).slice(0, 6);
    }
    function idTextColor(string) {
        if (tc.getBrightness() < 128){
            console.log('black');
            return 'black';
        }else{
            console.log('white');
            return 'white';

        }
    }
    
    useEffect(()=> {
        getThreadInfo();
        document.title = 'CoinChan.xyz - An Imageboard Dedicated to the Discussion of Crypto Microcap Gems'

    }, []);

    let getThreadInfo = async () => {
        let response = await fetch(backendUrl + '/getThread/' + postId + '/');
        let data = await response.json();
        setThreadInfo(data);
    };


    var quote = new RegExp('>>');
    var greentext = new RegExp('^>');
    


    


    return (
        <div>
        <Link to={'/' + board + '/'} style={{textDecoration: "none", color: "white"}}><Typography margin={2} variant='h5' align='center'>/{board}/ - {props.siteInfo.boardInfo && <span> {props.siteInfo.boardInfo[board].board_description}</span>} </Typography></Link>
        <br></br>
        <NewReplyForm  threadInfo={threadInfo} getThreadInfo={getThreadInfo} executeScroll={executeScroll}></NewReplyForm>
        <br></br>
        { threadInfo.op && threadInfo.op.is_locked && 
            <Grid item container alignItems='center' justifyContent='center' padding={2}>
            <Alert align="left" sx={{marginTop: '8px'}} variant="outlined" severity="error">
                This thread is locked. You may not reply.
            </Alert>
            </Grid>
        }
        {threadInfo.op &&


                <ThreadOp postInfo={threadInfo.op}></ThreadOp>


        }
      
        {threadInfo.replies && threadInfo.replies.map((post, index) =>{
            return <ThreadReply quoteVar={quoteVar} setQuoteVar={setQuoteVar}  key={index} postInfo={post} siteInfo={props.siteInfo}></ThreadReply>
        })}
        <br></br>
        <br></br>
        <br></br>

        <div ref={threadBottom}></div>
        <QuickReply quoteVar={quoteVar} setQuoteVar={setQuoteVar}  threadInfo={threadInfo} getThreadInfo={getThreadInfo} executeScroll={executeScroll}></QuickReply>
        
        </div>);
}
export default ViewThread;
