import logo from '../logo.svg';

import { useState, useEffect } from 'react';
import {Route, Routes} from "react-router";
import {Link} from "react-router-dom";
import * as React from 'react';

import {Grid, Button, Typography, Icon, Divider, Card, Alert, Box, Tooltip} from '@mui/material';
import ccLogo from '../components/img/coinchanlogotext.png';
import tgLogo from '../components/img/telegram.png';
import pooCoinLogo from '../components/img/poocoin512.png';
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import MobileBoardChanger from './MobileBoardChanger';
import Banner from './Banner';

const backendUrl = "https://data.coinchan.xyz";



const Header = () => {
    let [siteInfo, setSiteInfo] = useState([]);
    useEffect(()=> {
        getSiteInfo();
    }, []);
  
    let getSiteInfo = async () => {
        let response = await fetch(backendUrl + '/siteInfo/');
        let data = await response.json();
        setSiteInfo(data);
    };
    return(
    <div>
        <div>
        <span>[{'\u00A0'}</span>
        <Link to="/" style={{textDecoration: "none", color: "white"}}>Home{'\u00A0'}</Link>
        <Link to="advertise/" style={{textDecoration: "none", color: "white"}}>{'\u00A0'}Advertise</Link>
        <Box sx={{ display: { xs: 'none', sm: 'inline'}}}>
        {siteInfo.boards && siteInfo.boards.map((board, index) =>{
            
            return <Tooltip key={index} title={'/' + board.board_url + '/ - ' + board.board_description } placement="bottom"><Link to={'/' + board.board_url + '/'}  style={{textDecoration: "none", color: "white"}}>{'\u00A0'}/{board.board_url}/{'\u00A0'}</Link></Tooltip>
        })}
        </Box>

        <span>{'\u00A0'}]</span>
        </div>
        <br></br>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={10} md={3} style={{maxWidth: '400px'}} >
            <Link to="/"><img style={{width: "100%", maxWidth: "400px"}} src={ccLogo} ></img></Link>
        
            <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid container item xs={4} md={4} alignItems="center" justifyContent="center" >
                <a href='https://poocoin.app' target='_blank'><img style={{maxWidth: "32px"}} src={pooCoinLogo} ></img></a>
            </Grid>
            <Grid container item xs={4} md={4} alignItems="center" justifyContent="center" >
                <a href='https://t.me/coinchanofficial' target='_blank'><img style={{maxWidth: "32px"}} src={tgLogo} ></img></a>
            </Grid>
            <Grid container item xs={4} md={4} alignItems="center" justifyContent="center">
            <Link to="/advertise/" style={{color: "white"}}><CampaignSharpIcon /></Link>
            </Grid>
            </Grid>
            </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
        
        {siteInfo.alert &&
        <Grid item container alignItems='center' justifyContent='center' xs={10} md={4} >
            <Alert align='left' severity="info">{siteInfo.alert}</Alert>
        </Grid>
        }

        </Grid>

            <Banner/>

        
        <Box sx={{ display: { xs: 'block', sm: 'none'}}}>
        <br></br>
        <MobileBoardChanger siteInfo={siteInfo}></MobileBoardChanger>
        </Box>

        <br></br>  
    </div>
    );
}

export default Header;

      