import * as React from 'react';
import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {Grid, Button, Typography, Icon, Divider, Paper, Card, CardHeader, Box, Alert} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SendIcon from '@mui/icons-material/Send';
const backendUrl = "https://data.coinchan.xyz";

export default function NewThreadForm(props) {

  const [value, setValue] = useState('Controlled');
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpened, setIsOpened] = useState(false);
  const toggleIsOpened = () => {
    setIsOpened(isOpened => !isOpened);
    };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [postName, setPostName] = useState("");
  const [postContent, setPostContent] = useState("");
  const [postFile, setPostFile] = useState("");
  let handleSubmit = async (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('board', props.board);
    formData.append('post_name', postName);
    formData.append('post_content', postContent);
    formData.append('responding_to', "");
    formData.append('post_image', postFile);

    try {
      let res = await fetch(backendUrl + '/newThread/', {
        method: "POST",
        headers: {
            
        },
        body: formData
      });
      let resJson = await res.json();
      if (res.status === 200) {
        if (resJson.message == "success"){
            setAlertMessage("");
            props.getCatalogInfo();
        }
        if(resJson.message != "success"){
            setAlertMessage(resJson.message);
        }

      } else {
        console.log("An error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };








  return (
    <div>
    <Button onClick={toggleIsOpened} variant='outlined'>{isOpened ? 'Close Form' : 'Start Thread'}</Button>
    <form style={{display: isOpened? 'block' : 'none'}} onSubmit={handleSubmit}>
    <Grid container spacing={0} justifyContent='center'>
        
        <Grid padding={2} item xs={10} md={4} xl={3}>
            <TextField onChange={(e) => setPostName(e.target.value)} sx={{width: '100%'}} id="outlined-basic" label="Name" defaultValue='Anonymous' variant="outlined" />

            <Box

            sx={{
                '& .MuiTextField-root': {  width: '100%' },
                paddingTop: '10px',
            }}

            >
            <div>

                <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={5}
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                
                />
            </div>
            </Box>
            {alertMessage !== "" && 
            <Alert align="left" sx={{marginTop: '8px'}} variant="outlined" severity="error">
                {alertMessage}
            </Alert>
            }
            <Grid container paddingTop={2} >
                <Grid item xs={6}>
                <IconButton color="primary" aria-label="upload picture" accept='image/*' component="label">
                    <input onChange={(e) => setPostFile(e.target.files[0])} hidden accept="image" type="file" />
                    <PhotoCamera />
                </IconButton>
                <span>  Select File</span>
                </Grid>
                <Grid item xs={6}>
                <Button type='submit' variant="outlined" endIcon={<SendIcon />}>
                Post
                </Button>
                </Grid>
            </Grid>

            {postFile && <p style={{fontSize: '12px', paddingTop: '8px'}}>File: {postFile.name}     </p>}
        </Grid>
    </Grid>
    </form>
    </div>
  );
}
