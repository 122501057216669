import logo from '../logo.svg';


import * as React from 'react';
import {Link, Routes, Route, useParams, useLocation} from "react-router-dom";
import {Grid, Button, Typography, Icon, Divider, Paper, Card, Box} from '@mui/material';
import {useState, useEffect} from 'react';

import pepe from "./img/pepe.jpg";
import CatalogPost from "./CatalogPost";
import NewThreadForm from './NewThreadForm';
const backendUrl = "https://data.coinchan.xyz";


const Catalog = (props) => {
    const location = useLocation();
    let {board} = useParams();
    let [catalogInfo, setCatalogInfo] = useState([]);

    useEffect(()=> {
        getCatalogInfo();
        window.scrollTo(0, 0);
        
    }, [location]);

    let getCatalogInfo = async () => {
        let response = await fetch(backendUrl + '/' + board + '/catalog/');
        let data = await response.json();
        document.title = '/' + board + '/ - '  + data.board.board_description + ' - CoinChan.xyz';
        setCatalogInfo(data);
    };
    return (
        <div>
        <Typography margin={2} variant='h5' align='center'>/{board}/ - {props.siteInfo.boardInfo && <span> {props.siteInfo.boardInfo[board].board_description}</span>} </Typography>
        <Typography margin={2} variant='h5' align='center' sx={{fontSize: 14}}>Catalog</Typography>
        <br></br>
        <NewThreadForm getCatalogInfo={getCatalogInfo} board={board}></NewThreadForm>
        <br></br>
        <Grid container spacing={0} justifyContent='center'>
            <Grid padding={2} item xs={12} md={10} xl={10}>
                <Card sx={{background:"rgba(13, 41, 70, 1)"}}>
                    
                    <Grid container item xs={12}>
                    {catalogInfo.stickies && catalogInfo.stickies.map((post, index) =>{

                    return <CatalogPost key={index} postInfo={post} siteInfo={props.siteInfo}></CatalogPost>
                    })}
                    {catalogInfo.threads && catalogInfo.threads.map((post, index) =>{

                    return <CatalogPost key={index} postInfo={post} siteInfo={props.siteInfo}></CatalogPost>
                    })}


                    </Grid>
                </Card>
            </Grid>
        </Grid>

        </div>);
}
export default Catalog;