import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import {Route, Routes} from "react-router";
import {Link} from "react-router-dom";
import * as React from 'react';

import {Grid, Button, Typography, Icon, Divider, Card, Alert} from '@mui/material';
import ccLogo from './components/img/coinchanlogotext.png';
import tgLogo from './components/img/telegram.png';
import pooCoinLogo from './components/img/poocoin512.png';
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import Landing from './components/Landing';
import Catalog from './components/Catalog';
import Advertise from './components/Advertise';
import Header from './components/Header';
import ViewThread from './components/ViewThread';
const backendUrl = "https://data.coinchan.xyz";



function App() {
  let [siteInfo, setSiteInfo] = useState([]);
  useEffect(()=> {
      getSiteInfo();
  }, []);

  let getSiteInfo = async () => {
      let response = await fetch(backendUrl + '/siteInfo/');
      let data = await response.json();
      let boardDict = {};
      data.boards.forEach(
        element => boardDict[element.board_url] = element
        
      );
      data.boardInfo = boardDict;
      setSiteInfo(data);
  };
  return (
    <div className='App'>

      <Grid container spacing={0} justifyContent='center'>
      <Grid item xs={12} xl={10}>
      <Header></Header>

      <Routes>
        <Route path="/" element={<Landing siteInfo={siteInfo} />} />
        <Route path="/:board/" element={<Catalog siteInfo={siteInfo} />} />
        <Route path="/advertise/" element={<Advertise />} />
        <Route path="/:board/:postId/" element={<ViewThread siteInfo={siteInfo} />} />
      </Routes>   
      </Grid>
      </Grid>
    </div>
  );
}


export default App;
