import logo from '../logo.svg';

import sticky from './img/sticky.gif';
import locked from './img/locked.gif';
import * as React from 'react';
import {Link} from "react-router-dom";
import {Grid, Button, Typography, Icon, Divider, Paper, Card, Box} from '@mui/material';
const backendUrl = "https://data.coinchan.xyz";

const CatalogPost = (post) => {
    return(
    <Grid container padding={3} item xs={6} md={4} xl={2} alignItems='center' direction="column">
        <Typography sx={{fontWeight: 'bold', fontSize: 12}}>{post.postInfo.post_name} {post.postInfo.is_modpost && <span style={{color: 'red'}}>##Admin</span> }</Typography>
        <Typography sx={{fontSize: 12}}>No.{post.postInfo.local_id} - { new Date(post.postInfo.post_date + "Z").toLocaleString("en-US")}</Typography>
        {post.postInfo.post_image &&
            <div container style={{position: 'relative'}}>
                <Link to={'/' + post.postInfo.parent_board + '/' + post.postInfo.pk}><img src={backendUrl + post.postInfo.post_thumb} style={{width: "auto", maxWidth: "150px", maxHeight:'150px'}}></img></Link>
                {post.postInfo.is_sticky && post.postInfo.is_locked == false &&
                <img src={sticky} style={{position: 'absolute', top:0, right:0}}></img>
                }
                {post.postInfo.is_locked && post.postInfo.is_sticky == false &&
                <img style={{position: 'absolute', top:0, right:0, maxWidth: '18%'}} src={locked}></img>
                }
                {post.postInfo.is_locked && post.postInfo.is_sticky &&
                    <div>
                    <img src={sticky} style={{position: 'absolute', maxWidth: '15%', top:0, right:'15%'}}></img>
                    <img style={{position: 'absolute', top:0, right:0, maxWidth: '15%'}} src={locked}></img>
                    </div>
                }
            </div>
        }
        
        {post.postInfo.post_subject &&
        <Typography align='center' sx={{fontWeight: 'bold', fontSize: 14}}>{post.postInfo.post_subject}</Typography>
        }


        <Typography sx={{ fontSize: 10 }}>Replies: {post.postInfo.replies.length}</Typography>

        {post.postInfo.post_content && 
        <Box sx={{maxWidth : '150px' }}>
        <Typography sx={{ fontSize: 14, wordWrap :'break-word'}}>{post.postInfo.post_content.slice(0, 120)} {post.postInfo.post_content.length > 120 && <span>...</span>}</Typography>
        </Box>
        }
    </Grid>
    );
}

export default CatalogPost;