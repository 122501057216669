import * as React from 'react';
import {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import {Grid, Button, Typography, Icon, Divider, Paper, Card, CardHeader, Box, Alert} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SendIcon from '@mui/icons-material/Send';
import RateReviewIcon from '@mui/icons-material/RateReview';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
const backendUrl = "https://data.coinchan.xyz";

export default function QuickReply(props) {


    const [isOpened, setIsOpened] = useState(false);
    const [pending, setPending] = useState("");
    const [postName, setPostName] = useState("");
    const [postContent, setPostContent] = useState("");
    const [postFile, setPostFile] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    useEffect(() => { 

        window.addEventListener('addQuote', (event) => {

            setPostContent(postContent + ">>" +  event.localId + "\n")
            if (isOpened === false){
                setIsOpened(true);
            }
        
          });
        
    
    });






    const toggleIsOpened = () => {
        if (isOpened == true){
            setPostContent("");
          }
      setIsOpened(isOpened => !isOpened);

      };
  



    const checkQuote = () => {
        if (props.quoteStack.length !== 0){
            if (isOpened == false){
                 toggleIsOpened();
             }
             
             var lastContent = postContent;
             var newContent = postContent+ ">>" + props.quoteStack + "\n";
             
             props.setQuoteStack("");
             setPostContent(newContent);
         } 
    }
    
    let handleSubmit = async (e) => {
      e.preventDefault();
      var formData = new FormData();
      formData.append('board', props.threadInfo.op.parent_board);
      formData.append('post_name', postName);
      formData.append('post_content', postContent);
      formData.append('responding_to', props.threadInfo.op.pk);
      formData.append('post_image', postFile);
  
      try {
        let res = await fetch(backendUrl + '/newPost/', {
          method: "POST",
          headers: {
              
          },
          body: formData
        });
        let resJson = await res.json();
          if (res.status === 200) {
            if (resJson.message == "success"){
              setAlertMessage("");
              setPostName("Anonymous");
          
              setPostContent("");
              setPostFile("");
              console.log('Success');
              props.getThreadInfo();
    
              toggleIsOpened();
          }
          if(resJson.message != "success"){
              setAlertMessage(resJson.message);
          }

        } else {
          console.log("An error occured");
        }
      } catch (err) {
        console.log(err);
      }
    };






  return (
    <div>
    <Box sx={{marginBottom: {xs: '64px', sm: '16px'}, width: {xs: '100%', sm: '400px' } }} style={{position: 'fixed', right: '0', bottom: '0'}}>
    
    <form style={{display: isOpened? 'block' : 'none'}} onSubmit={handleSubmit}>
    <Grid container spacing={0} justifyContent='center'>
        
        <Grid padding={2} item xs={12}>
            <Card sx={{backdropFilter:"blur(5px)",backgroundColor: 'rgba(12, 37, 62, 0.9)', padding: 2}}>
            <TextField onChange={(e) => setPostName(e.target.value)} sx={{width: '100%'}} id="outlined-basic" label="Name" defaultValue='Anonymous' variant="outlined" />

            <Box

            sx={{
                '& .MuiTextField-root': {  width: '100%' },
                paddingTop: '10px',
            }}

            >
            <div>

                <TextField
                id="postContentField"
                label="Comment"
                multiline
                rows={5}
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                
                />
            </div>
            {alertMessage !== "" && 
            <Alert align="left" sx={{marginTop: '8px'}} variant="outlined" severity="error">
                {alertMessage}
            </Alert>
            }
            </Box>
            
            <Grid container paddingTop={2} >
                <Grid item xs={6}>
                <IconButton color="primary" aria-label="upload picture" component="label">
                    <input onChange={(e) => setPostFile(e.target.files[0])} hidden accept="image" type="file" />
                    <PhotoCamera />
                </IconButton>
                <span>  Select File</span>
                </Grid>
                <Grid item xs={6}>
                <Button type='submit' variant="outlined" endIcon={<SendIcon />}>
                Post
                </Button>
                </Grid>
            </Grid>

            {postFile && <p style={{fontSize: '12px', paddingTop: '8px'}}>File: {postFile.name}     </p>}
            </Card>
        </Grid>
    </Grid>
    </form>
    <Box sx={{display: {xs: 'none', sm: 'block'}}}>
    <Button color='primary' sx={{marginTop: 2, marginBottom: 2}} onClick={toggleIsOpened} variant='contained'>{isOpened ? 'Close Form' : 'Post Reply'}</Button>
    </Box>

    </Box>
    <Box sx={{display: {xs: 'block', sm: 'none'},width: '100%', backdropFilter:"blur(5px)",backgroundColor: 'rgba(10, 25, 41, 0.9)', position: 'fixed', right: '0', bottom: '0', marginTop: '64px'}}>
    <Button color='primary' sx={{marginTop: 2, marginBottom: 2}} onClick={toggleIsOpened} variant='outlined'>{isOpened ? 'Close Form' : 'Post Reply'}</Button>
    </Box>
    </div>
  );
}
