import logo from '../logo.svg';


import * as React from 'react';
import {Link} from "react-router-dom";
import {Grid, Button, Typography, Icon, Divider, Paper, Card, Box, DataGrid} from '@mui/material';
import CampaignTable from './CampaignTable';
import AddCampaignModal from './AddCampaignModal';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
const backendUrl = "https://data.coinchan.xyz";

const Advertise = () => {
    
    return(
        <div>
        <Grid container spacing={0}>
            <Grid padding={2} item xs={12} md={4}>
                
                <Card sx={{background:"rgba(13, 41, 70, 1)"}}>
                <Typography margin={2} variant='h5'>CoinChan Ads</Typography>
                <Typography align='left' margin={2}>
                    CoinChan Ads uses a bid-based ad system in which you purchase ad space by burning CHAN.
                    Every time a user visits a page on CoinChan.xyz, an ad is chosen to be displayed to them 
                    based on the campaign's weighted CHAN balance. For example, if your ad campaign is 
                    funded with 10,000 CHAN, and the total CHAN balance of all ads combined is 100,000 CHAN,
                    your ad will display 10% of the time anyone views a page. You can see how many times CoinChan
                    users have viewed your ad on its campaign detail page in this ad console. You can always add 
                    more CHAN to your ad if you'd like to increase the number of times it is shown to users.

                </Typography>
                </Card>
            </Grid>
            <Grid padding={2}  paddingLeft={{xs: 2, sm: 2, lg: 0}} item xs={12} md={8}>
                <Card  >
                    <Typography  display='inline' margin={2} marginRight={0} variant='h5' align='center'>Coming Soon</Typography>
                    <Box height='500px'>

                    </Box>
                
                </Card>
            </Grid>
        </Grid>

        </div>
    );
}

export default Advertise;