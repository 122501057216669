import logo from '../logo.svg';

import { useState, useEffect } from 'react';
import {Route, Routes} from "react-router";
import {Link, Redirect, useNavigate} from "react-router-dom";
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {Grid, Button, Typography, Icon, Divider, Card, Alert, Box, Tooltip} from '@mui/material';



const backendUrl = "https://data.coinchan.xyz";



const Header = () => {
    let location = useLocation();
    let [banner, setBanner] = useState([]);
    useEffect(()=> {
        getBanner();

    }, [location]);
  
    
    let getBanner = async () => {
        let response = await fetch(backendUrl + '/getBanner/');

        let data = await response.json();

        setBanner(data);
    };
    return(
        <Grid marginTop="16px" item container alignItems='center' justifyContent='center' padding={2}>
        {banner.ad &&
        <Grid item xs={12} sm={6} lg ={4}>
        <a href={banner.ad.banner_link}><img style={{maxWidth:'100%'}} src={backendUrl + banner.ad.banner_img}></img></a>
        <Link to='/advertise' style={{color: 'white'}}><Typography fontSize={12}>Your Banner Here</Typography></Link>
        </Grid>
        }   
        
        </Grid>

    );
}

export default Header;

      