import logo from '../logo.svg';


import * as React from 'react';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {Grid, Button, Typography, Icon, Divider, Paper, Card, Box} from '@mui/material';
import pepe from "./img/pepe.jpg";
import PostPreview from "./PostPreview";
const backendUrl = "https://data.coinchan.xyz";



const Landing = (props) => {
    let [landingInfo, setLandingInfo] = useState([]);
    useEffect(()=> {
        getLandingInfo();
        document.title = 'CoinChan.xyz - An Imageboard Dedicated to the Discussion of Crypto Microcap Gems'
    }, []);

    let getLandingInfo = async () => {
        let response = await fetch(backendUrl + '/landing/');
        let data = await response.json();
        setLandingInfo(data);
    };

    return (
        <div>
        <Grid container spacing={0}>
            <Grid padding={2} item xs={12} md={4}>
                
                <Card sx={{background:"rgba(13, 41, 70, 1)"}}>
                <Typography margin={2} variant='h5' align='center'>About</Typography>
                <Typography align='left' margin={2}>{props.siteInfo.site_desc}</Typography>
                <Typography margin={2} variant='h5' align='center'>News</Typography>
                <Typography sx={{fontSize:16, fontWeight:'bold'}} align='left' margin={2}>A New Beginning for CoinChan </Typography>
                <Typography sx={{fontSize: 12}} style={{paddingLeft: 16}} align='left'>by Gunky</Typography>
                <Typography sx={{fontSize:14}} align='left' margin={2}>Hi everyone, I've recently purchased CoinChan.xyz from its original creator and have overhauled the site with a new interface and more robust backend. There are several major changes to the ad system in the works, not to mention
                the huge amount of work ahead in making the site not look/work like hot garbage... Stay tuned for more updates but for now shill to your heart's content.
                </Typography>
                </Card>
            </Grid>
            <Grid padding={2} paddingLeft={{xs: 2, sm: 2, lg: 0}} item xs={12} md={8}>
                <Card sx={{background:"rgba(13, 41, 70, 1)"}}>
                    <Typography margin={2} variant='h5' align='center'>Latest Threads</Typography>
                    <Grid container item xs={12}>
                    {landingInfo.posts && landingInfo.posts.map((post, index) =>{

                    return <PostPreview key={index} postInfo={post} siteInfo={props.siteInfo}></PostPreview>
                    })}


                    </Grid>
                </Card>
            </Grid>
        </Grid>

        </div>);
}
export default Landing;