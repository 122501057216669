import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Link, Redirect, useNavigate} from "react-router-dom";
export default function MobileBoardChanger(props) {
  const navigate = useNavigate();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
    navigate('/' + event.target.value + '/');
  };
  
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
            <em>Select Board</em>
          </MenuItem>
          {props.siteInfo.boards && props.siteInfo.boards.map((board, index) =>{
            
            return <MenuItem key={index} value={board.board_url} title={'/' + board.board_url + '/ - ' + board.board_description } placement="bottom">{'/' + board.board_url + '/ - ' + board.board_description }</MenuItem>
            })}

        </Select>
        
      </FormControl>

      
    </div>
  );
}
