import logo from '../logo.svg';


import * as React from 'react';
import {memo} from 'react';
import md5 from 'md5';
import {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {Tooltip, Grid, Button, Typography, Icon, Divider, Paper, Card, CardHeader, Box, Chip} from '@mui/material';
import pepe from "./img/pepe.jpg";
import Backlink from './BackLink';
import PeekReply from"./PeekReply";
import tinycolor from 'tinycolor2';
const backendUrl = "https://data.coinchan.xyz";




const ThreadReply = (props) => {

    const [isPeeking, setIsPeeking] = useState(false);
    const [isThumb, setIsThumb] = useState(true);
    const [backlinks, setBacklinks] = useState([]);
    var quote = new RegExp('>>');
    var greentext = new RegExp('^>');
    var tc = tinycolor();
    function idColor(string) {
        return '#' + md5(string).slice(0, 6);
    }
    function idTextColor(string) {
        var thisIdColor;
        thisIdColor = tinycolor(string);
        if (thisIdColor.getBrightness(string) > 128){

            return 'black';
        }else{

            return 'white';
        }
    }
    const toggleIsThumb = () => {
        setIsThumb(isThumb => !isThumb);

    };



    function getImage(){
        if(isThumb){
            return props.postInfo.post_thumb;
        }else{
            return props.postInfo.post_image;
        }
    }
    function getStyle(){
        if(isThumb){
            return {float: 'left', width: "auto", maxWidth: "200px", maxHeight:'300px', padding: '20px', paddingTop: '4px'};
        }else{
            return { width: "auto", height: 'auto', maxWidth: "100%",  padding: '20px', paddingTop: '4px'};
        }
    }
    function getMobileStyle(){
        if(isThumb){
            return {float: 'left', width: "auto", maxWidth: "150px", maxHeight:'300px', padding: '20px', paddingTop: '2px'};
        }else{
            return { width: "auto", height: 'auto', maxWidth: "100%",  padding: '20px', paddingTop: '2px'};
        }
    }
    function isOp(){
        if(props.postInfo.parent_post){
            return "false";
        } else{
            return "true";
        }
    }
    useEffect(() => {
        window.addEventListener('newBacklink', (event) => {
            var thisReplies = event.data["bl" + props.postInfo.local_id]
            setBacklinks([...thisReplies]);
        });

    
      }, []);
    return (
        <div>
        {props.postInfo.local_id &&
        <Grid id={props.postInfo.local_id} container spacing={0} justifyContent='center'>

            <Grid container item flexWrap='nowrap'  xs={12} md={10} paddingTop={1} paddingLeft={2} paddingRight={2}  >

            <Card sx={{background:"#0d2946"}}>
            <Box sx={{ marginTop:0, background:"#183049"}}>
            <Typography sx={{color: '#B268BB', fontSize: 14}} marginLeft={1} marginRight={1} align='left'>
            <span  style={{color: '#B268BB', fontWeight:'bold'}}>{props.postInfo.post_subject} </span> 
            <span style={{color: 'white', fontWeight:'bold'}}>  {props.postInfo.post_name}</span>
            {props.postInfo.is_modpost && <span style={{color: 'red'}}>##Admin</span> }
            <span style={{color: 'white'}}>   ID:(<Box style={{color: idTextColor(idColor(props.postInfo.post_tid)), padding: '0 4px', height: '14px', fontSize: 10, backgroundColor: idColor(props.postInfo.post_tid), display: 'inline-block', borderRadius: '8px'}}><span sx={{fontSize: 4 }}>{props.postInfo.post_tid}</span></Box>)</span>
            <span style={{color: 'white'}}>   {new Date(props.postInfo.post_date + "Z").toLocaleString("en-US")} No.</span>
            <Tooltip disableFocusListener disableTouchListener title="Reply to this post">
            <a href='#!' style={{color: 'white', textDecoration: 'none'}} onClick={() => (window.addReply(props.postInfo.local_id))}>{props.postInfo.local_id}</a>

            </Tooltip>
            <span> {backlinks && backlinks.map(backlink => {
                return <Backlink target={backlink.replace("bl", "")} parent={props.postInfo.local_id} />;

            })}</span>

            </Typography>
            </Box>

                {/* Load two separate images for mobile and desktop since
                this shit doesn't support breakpoints to set the fucking width
                */}
                {props.postInfo.post_thumb &&
                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                <img onClick={toggleIsThumb} style={getMobileStyle()}  src={backendUrl + getImage()}></img>
                </Box>
                }
                {props.postInfo.post_thumb &&
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                <img onClick={toggleIsThumb} style={getStyle()}  src={backendUrl  + getImage()}></img>
                </Box>
                } 
                  
                
                
                <Typography align='left' width='100%' fontSize={{sm: 14, xs: 14}}  lineHeight={1.5} style={{padding: '8px', whiteSpace: 'pre-line' }}>{props.postInfo.post_content.split(/\r?\n/).map(line => {
                    
                    return <div key={line +"div"}>
                        {quote.test(line) && 
                            <div> 
                                
                                <PeekReply thisReply={props.postInfo.local_id} line={line} />
                                
                            </div>}
                            {quote.test(line) != true && greentext.test(line) &&  <div>
                                <span key={line + "t"} style={{color: '#66bb6a'}}>{line}</span>
                            </div>}
                            
                            {quote.test(line) != true && greentext.test(line) != true && <div>
                                <span key={line + "q"}>{line}</span>
                                </div>
                            }

                            {
                                line == "" && <span>{'\n'}</span>
                            }
                                

                            
                        </div>;

                })}</Typography>
        
                
            </Card>
            </Grid>
        </Grid>
        }
        <div style={{display: 'none'}}>
            <div id={props.postInfo.local_id + "Content"}>{props.postInfo.post_content}</div>
            <div id={props.postInfo.local_id + "Name"}>{props.postInfo.post_name}</div>
            <div id={props.postInfo.local_id + "Thumb"}>{props.postInfo.post_thumb}</div>
            <div id={props.postInfo.local_id + "Date"}>{props.postInfo.post_date + "Z"}</div>
            <div id={props.postInfo.local_id + "Tid"}>{props.postInfo.post_tid}</div>
            <div id={props.postInfo.local_id + "isModpost"}>{String(props.postInfo.is_modpost)}</div>
            <div id={props.postInfo.local_id + "isOp"}>false</div>

        </div>
        </div>);
}
export default React.memo(ThreadReply);

//<div><span key={line} style={{ color: quote.test(line) && '#3399ff'}}>{line}</span>{'\r\n'}</div>;